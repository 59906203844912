<template>
  <div
    v-if="showBack"
    :class="$style.back"
    title="Back"
    @click="back"
  >
    <font-awesome-icon :icon="['fal', 'arrow-left']" />
  </div>
</template>

<script>
export default {
  name: 'Back',
  props: {
    depth: {
      type: Number,
      default: 2,
    },
    redirects: {
      type: Array,
      default: () => [],
    },
    hideFor: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showBack() {
      return this.$route.matched.length > this.depth && !this.hideFor.includes(this.$route.name);
    },
  },
  methods: {
    back() {
      if (this.redirects.length) {
        const current = this.redirects.find(({ from }) => from === this.$route.name);

        if (current) return this.$router.push({ name: current.to });
      }

      // If parent route have redirect to current route, go up two levels instead one

      const redirect = this.$route.matched.slice(-this.depth)?.[0]?.redirect.name
        === this.$route.matched.slice(-(this.depth - 1))?.[0].name;

      return this.$router.push({
        name: this.$route.matched.slice(redirect ? -(this.depth + 1) : -this.depth)?.[0].name,
      });
    },
  },
};
</script>

<style lang="scss" module>
.back {
  align-items: center;
  margin-right: 10px;
  color: var(--current-color);
  transition-duration: var(--transition-duration);
  cursor: pointer;

  svg {
    display: block;
    width: 16px;
    height: 16px;
  }

  &:hover {
    transform: scale(1.4);
  }

  @media (max-width: 768px) {
    svg {
      display: initial;
      width: 20px;
      height: 20px;
    }

    &:hover {
      transform: scale(1.4);
    }
  }
}
</style>
