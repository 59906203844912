<template>
  <div>
    <div :class="$style.title">
      <Back
        :redirects="[
          {
            from: 'AuditLog',
            to: 'AccountSettings',
          },
        ]"
        :depth="0"
      />
      <settings-title :value="'Audit log'" />
    </div>

    <table :class="$style['controls-table']">
      <tr>
        <td>
          <div :class="$style.export">
            <b-button
              type="is-light"
              :class="$style['export-btn']"
              @click="exportCsv"
            >
              Export to csv
            </b-button>
          </div>
        </td>
        <td>
          <div :class="$style.date">
            <b-field label="Date range">
              <date-range-picker
                v-model="dateFilter"
                :ranges="ranges"
                opens="left"
                @update="onDateChange"
              >
                <div
                  slot="input"
                  slot-scope="picker"
                >
                  {{ formatDate({ date: picker.startDate, format: 'DD/MM/YYYY' }) }} -
                  {{ formatDate({ date: picker.endDate, format: 'DD/MM/YYYY' }) }}
                </div>
              </date-range-picker>
            </b-field>
          </div>
        </td>
      </tr>
    </table>

    <div>
      <b-table :data="getLogs">
        <b-table-column
          v-slot="{ row }"
          label="User"
          width="18%"
        >
          <p>
            <span style="font-size: 16px; font-weight: 700;">{{ row.userInfoRow1 }}</span>
            <br> <span style="font-size: 14px">{{ row.userInfoRow2 }}
              <br> {{ row.userInfoRow3 }}</span>
          </p>
        </b-table-column>

        <b-table-column
          v-slot="{ row }"
          field="action"
          label="Action"
          sortable
        >
          {{ row.action }}
        </b-table-column>

        <b-table-column
          v-slot="{ row }"
          field="category"
          label="Category"
          sortable
        >
          {{ row.category }}
        </b-table-column>

        <b-table-column
          v-slot="{ row }"
          field="scope"
          label="Scope"
          sortable
        >
          {{ row.scope }}
        </b-table-column>
      </b-table>

      <infinite-loading :identifier="infiniteId" @infinite="loadMoreItems">
        <span slot="no-more" />
        <div slot="spinner">
          <spinner />
        </div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DateRangePicker from 'vue2-daterange-picker';
import InfiniteLoading from 'vue-infinite-loading';
import { mapState, mapActions } from 'pinia';
import { useAuditLogsStore } from '@/stores/index.js';
import SettingsTitle from '@/views/settings/components/title.vue';
import Back from '@/components/back.vue';
import Spinner from '@/components/spinner.vue';
import { formatDate } from '@/utils/index.js';

export default {
  name: 'Audit',
  components: {
    DateRangePicker,
    SettingsTitle,
    InfiniteLoading,
    Back,
    Spinner,
  },
  data() {
    return {
      dateFilter: {
        startDate: dayjs().startOf('day').subtract(7, 'days'),
        endDate: dayjs().endOf('day'),
      },
      metadata: null,
      infiniteId: +new Date(),
      ranges: {
        Today: [
          dayjs().startOf('day').toDate(),
          dayjs().endOf('day').toDate(),
        ],
        'This week': [
          dayjs().startOf('week').toDate(),
          dayjs().endOf('week').toDate(),
        ],
        'Last week': [
          dayjs().subtract(1, 'week').startOf('week').toDate(),
          dayjs().subtract(1, 'week').endOf('week').toDate(),
        ],
        'This month': [
          dayjs().startOf('month').toDate(),
          dayjs().endOf('month').toDate(),
        ],
        'Last month': [
          dayjs().subtract(1, 'month').startOf('month').toDate(),
          dayjs().subtract(1, 'month').endOf('month').toDate(),
        ],
        'This year': [
          dayjs().startOf('year').toDate(),
          dayjs().endOf('year').toDate(),
        ],
      },
    };
  },

  computed: {
    ...mapState(useAuditLogsStore, {
      getLogs: 'logs',
      isLastPage: 'isLastPage',
    }),
    from() {
      return dayjs(this.dateFilter.startDate).startOf('day').toISOString();
    },
    to() {
      return dayjs(this.dateFilter.endDate).endOf('day').toISOString();
    },
  },

  methods: {
    ...mapActions(useAuditLogsStore, {
      downloadLogs: 'downloadAuditLogs',
      readCsv: 'exportAuditToCsv',
      clearAuditLogs: 'clearAuditLogs',
    }),
    formatDate,
    async onDateChange() {
      this.clearAuditLogs();

      // reset infinite loader
      this.infiniteId += 1;
    },
    async exportCsv() {
      await this.readCsv({ from: this.from, to: this.to });
    },

    async loadMoreItems($state) {
      await this.downloadLogs({ from: this.from, to: this.to });

      $state.loaded();

      if (this.isLastPage) {
        $state.complete();
      }
    },
  },

  beforeDestroy() {
    this.clearAuditLogs();
  },

};
</script>

<style lang="scss" module>
  .title {
    display: flex;
    align-items: baseline;
    min-height: 57px;
  }

  .controls-table {
    margin-bottom: 40px;

    .date {
      display: flex;
      justify-content: flex-end;
    }

    .export-btn {
      margin-top: 28px;
    }
  }

  .audit {
    .table {
      margin-bottom: 20px;
    }
  }

  .btnIcon {
    margin-right: 4px;
    margin-left: -4px;
  }
</style>
