<template>
  <div
    :class="$style.spinner"
    :style="{
      '--size': `${size}px`,
      '--width': `${width}px`,
      '--color': color,
      '--duration': `${duration}ms`,
    }"
  />
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 500,
    },
  },
};
</script>

<style lang="scss" module>
.spinner {
  display: inline-flex;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  border: var(--width) solid var(--color, var(--current-color));
  border-right-color: transparent;
  border-top-color: transparent;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: var(--duration);
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
}
</style>
